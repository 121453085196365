import React from 'react'
//import { Link } from 'gatsby'
// Team from '../images/teamCork.jpg'
import Layout from '../components/layout'

const Contact = () => (
  <Layout>
    <h1>Contact Us</h1>
    <h3>Email:</h3>
    <a href="mailto:waterforddragonwarriors@gmail.com">WaterfordDragonWarriors@gmail.com</a>

  </Layout>
)

export default Contact
